"use client";
import type { Locale } from "@commerce-frontend/i18n/types";
import { ContentPreviewProvider } from "@commerce-frontend/storyblok-preview/provider";
import { ClientGqlFetcherProvider, initClientFetcher } from "@labdigital/graphql-fetcher";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import type { AbstractIntlMessages } from "next-intl";
import { NextIntlClientProvider } from "next-intl";
import { useState } from "react";
import type { PublicEnvVars } from "~/lib/config/context";
import { EnvVarsProvider } from "~/lib/config/context";
import { getAccessToken } from "~/lib/helpers/auth";
import { NavigationProvider } from "~/lib/navigation-provider";
import type { StoreConfig } from "~/lib/store-config/context";
import { StoreConfigProvider } from "~/lib/store-config/context";

type Props = {
	children?: React.ReactNode;
	locale: Locale;
	storeConfig: StoreConfig;
	apiHostname: string;
	messages: AbstractIntlMessages;
	envVars: PublicEnvVars;
	draftMode: boolean;
};

const MINUTES = 60 * 1000;

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 15 * MINUTES,
		},
	},
});

/**
 * Providers used to add context to client components, use this to wrap the first client component it hits
 * @returns
 */
export const Providers = ({
	children,
	storeConfig,
	apiHostname,
	locale,
	messages,
	envVars,
	draftMode,
}: Props) => {
	// Initialize the fetcher with the API hostname which is only available as a prop
	// useState is the recommended way to hold a stable value so we only initialize the fetcher once
	const [fetcher] = useState(() =>
		initClientFetcher(`${apiHostname}/${locale.toLowerCase()}/graphql`, {
			defaultHeaders: {
				"X-StoreContext-StoreKey": storeConfig.storeKey,
				"X-StoreContext-Locale": locale,
				"X-StoreContext-Currency": storeConfig.currency,
			},
		}),
	);

	return (
		<EnvVarsProvider envVars={envVars}>
			<StoreConfigProvider config={storeConfig}>
				<NavigationProvider>
					<NextIntlClientProvider locale={locale} messages={messages} timeZone="UTC">
						<ClientGqlFetcherProvider
							fetcher={async (...args) => {
								await getAccessToken(apiHostname, locale, storeConfig);
								return fetcher(...args);
							}}
						>
							<QueryClientProvider client={queryClient}>
								{draftMode ? (
									<ContentPreviewProvider fetcher={fetcher}>{children}</ContentPreviewProvider>
								) : (
									children
								)}
							</QueryClientProvider>
						</ClientGqlFetcherProvider>
					</NextIntlClientProvider>
				</NavigationProvider>
			</StoreConfigProvider>
		</EnvVarsProvider>
	);
};
