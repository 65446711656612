"use client";

import type { JSX, PropsWithChildren } from "react";
import { createContext, useContext } from "react";
import type { EnvVars, PublicEnvVars } from "./types";

export const EnvVarsContext = createContext<PublicEnvVars | null>(null);

type EnvVarsProviderProps = PropsWithChildren<{
	envVars: PublicEnvVars;
}>;

export const EnvVarsProvider = <T extends EnvVars>({
	children,
	envVars,
}: EnvVarsProviderProps): JSX.Element => (
	<EnvVarsContext.Provider value={envVars}>{children}</EnvVarsContext.Provider>
);

export const useEnvVar = <T extends keyof PublicEnvVars>(key: T): PublicEnvVars[T] => {
	const envVars = useContext(EnvVarsContext);

	if (!envVars) {
		throw new Error("envVars is null");
	}

	return envVars[key];
};
