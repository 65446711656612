import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/app/frontend/site/src/app/[locale]/providers.tsx");
;
import(/* webpackMode: "eager" */ "/app/frontend/site/src/lib/store-config/context/storeConfig.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.15.2_next@15.2.4_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwright+te_0c4fe260f6ef0a4d7f2fd28a786cd267/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwright+test@1.47.0_react-d_15f5c6c2f85109b313f8e5c0ab34b651/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwright+test@1.47.0_react-d_15f5c6c2f85109b313f8e5c0ab34b651/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin-ext\"],\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\",\"800\"],\"display\":\"swap\",\"variable\":\"--font-inter\"}],\"variableName\":\"fontInter\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwright+test@1.47.0_react-d_15f5c6c2f85109b313f8e5c0ab34b651/node_modules/next/font/local/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../fonts/texgyreheros-bold-webfont.woff\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../fonts/texgyreheros-regular-webfont.woff\",\"weight\":\"400\",\"style\":\"normal\"}],\"display\":\"swap\",\"variable\":\"--font-tex-gyre-heros\"}],\"variableName\":\"fontTexHyreGeros\"}");
