"use client";

import type { ClientFetcher } from "@labdigital/graphql-fetcher";
import { registerStoryblokBridge, storyblokInit } from "@storyblok/react";
import { useSearchParams } from "next/navigation";
import type React from "react";
import { useEffect, useState } from "react";
import { storeContentPreviewData } from "./store";

type Props = {
	children: React.ReactNode;
	fetcher: ClientFetcher;
};

/**
 * A very simple client side wrapper component needed for registering storyblok.
 *
 * `onUpdate` should be a NextJS server action, that revalidates the current
 * route and updates the cache.
 */
export function ContentPreviewProvider({ children, fetcher }: Props): React.ReactElement {
	const searchParams = useSearchParams();
	const storyId = searchParams.get("_storyblok");
	const spaceId = searchParams.get("_storyblok_tk[space_id]");

	const setHtmlAttributes = () => {
		const elements = document.querySelectorAll("[data-component-id]");
		for (const element of elements) {
			const componentType = element.getAttribute("data-component-type");
			const componentId = element.getAttribute("data-component-id");

			element.setAttribute(
				"data-blok-c",
				JSON.stringify({
					name: componentType,
					space: spaceId,
					uid: componentId,
					id: storyId,
				}),
			);
			element.setAttribute("data-blok-uid", `${storyId}-${componentId}`);
		}
	};

	const [clientSide, setClientSide] = useState(false);
	useEffect(() => {
		setClientSide(true);

		// Initialize the storyblok/react component. We only use this for
		// registering the bridge.
		storyblokInit({});

		if (!storyId && spaceId) return;
		setHtmlAttributes();
	}, [storyId, spaceId]);

	/**
	 * Registers the storyblok bridge to listen to iframe postmessages containing
	 * the updated story from the storyblok editor.
	 */
	useEffect(() => {
		const isBridgeEnable =
			typeof window !== "undefined" &&
			storyId &&
			typeof window.storyblokRegisterEvent !== "undefined";

		if (!isBridgeEnable) return;

		// Listen to iframe postmessages from the storyblok editor
		registerStoryblokBridge(
			Number(storyId),
			// eslint-disable-next-line  @typescript-eslint/no-misused-promises
			storeContentPreviewData(fetcher, () => {
				// TODO: This is a hack to make sure the html attributes are set
				// correctly after the storyblok bridge has updated the store.
				// We need to listen properly on invalidate things
				setTimeout(() => {
					setHtmlAttributes();
				}, 1000);
			}),
			{
				resolveRelations: ["usps"],
				// https://github.com/storyblok/storyblok/issues/782
				resolveLinks: "url",
			},
		);
	}, []);

	return (
		<>
			{/* Disable navigation for live editing */}
			{clientSide && storyId && <style>{"a { pointer-events: none !important; }"}</style>}
			{children}
		</>
	);
}
